
export const ADDRESS = {
    POLYGON: {
      LIQUIDITY: "0x8141bcfbcee654c5de17c4e2b2af26b67f9b9056",
      TICKET: "0x6a304dFdb9f808741244b6bfEe65ca7B3b3A6076",
      YIELDSOURCE: "0xD4F6d570133401079D213EcF4A14FA0B4bfB5b9C",
      AAVETOKEN: "0x1a13f4ca1d028320a707d99520abfefca3998b7f",
      DISTRIBUTOR: "0x8141BcFBcEE654c5dE17C4e2B2AF26B67f9B9056",
      POOL: "0x19DE635fb3678D8B8154E37d8C9Cdf182Fe84E60",
      AAVEINCENTIVES: "0x357D51124f59836DeD84c8a1730D72B749d8BC23",
      PRIZETIER: "0x1DcaD946D10343cc4494D610d6273153FB071772",
      AAVEDATA: "0x7551b5D2763519d4e37e8B81929D336De671d46d",
      USDC: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174"
  
    },
    ETHEREUM: {
      LIQUIDITY: "0xb9a179DcA5a7bf5f8B9E088437B3A85ebB495eFe",
      TICKET: "0xdd4d117723C257CEe402285D3aCF218E9A8236E1",
      YIELDSOURCE: "0x32e8D4c9d1B711BC958d0Ce8D14b41F77Bb03a64",
      AAVETOKEN: "0xbcca60bb61934080951369a648fb03df4f96263c",
      DISTRIBUTOR: "0xb9a179DcA5a7bf5f8B9E088437B3A85ebB495eFe",
      POOL: "0xd89a09084555a7D0ABe7B111b1f78DFEdDd638Be",
      AAVEINCENTIVES: "0xd784927Ff2f95ba542BfC824c8a8a98F3495f6b5",
      PRIZETIER: "0x24C3e15BdC10Ce2CB1BEc56cd43F397cE9B89430",
      AAVEDATA: "0x057835Ad21a177dbdd3090bB1CAE03EaCF78Fc6d", //v2
      USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
      
    },
    AVALANCHE: {
      LIQUIDITY: "0x83332f908f403ce795d90f677ce3f382fe73f3d1",
      TICKET: "0xB27f379C050f6eD0973A01667458af6eCeBc1d90",
      YIELDSOURCE: "0x7437db21A0dEB844Fa64223e2d6Db569De9648Ff",
      AAVETOKEN: "0x46a51127c3ce23fb7ab1de06226147f446e4a857",
      DISTRIBUTOR: "0x83332F908f403ce795D90f677cE3f382FE73f3D1",
      POOL: "0xF830F5Cb2422d555EC34178E27094a816c8F95EC",
      AAVEINCENTIVES: "0x01D83Fe6A10D2f2B7AF17034343746188272cAc9",
      PRIZETIER: "0xC3DAD539E460103c860Bb9Ca547647EDbD4903b6",
      AAVEDATA: "0x65285E9dfab318f57051ab2b139ccCf232945451",
      USDC: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664" // usdc.e
    },
    
    OPTIMISM: {
      LIQUIDITY: "0x722e9BFC008358aC2d445a8d892cF7b62B550F3F",
      TICKET: "0x62BB4fc73094c83B5e952C2180B23fA7054954c4",
      YIELDSOURCE: "0x4ecB5300D9ec6BCA09d66bfd8Dcb532e3192dDA1",
      AAVETOKEN: "0x625E7708f30cA75bfd92586e17077590C60eb4cD", // held on yield source
      DISTRIBUTOR: "0x722e9BFC008358aC2d445a8d892cF7b62B550F3F",
      POOL: "0x79Bc8bD53244bC8a9C8c27509a2d573650A83373",
      AAVEINCENTIVES: "0x929EC64c34a17401F460460D4B9390518E5B473e",
      PRIZETIER: "0xC88f04D5D00367Ecd016228302a1eACFaB164DBA", 
      AAVEDATA: "0x69FA688f1Dc47d4B5d8029D5a35FB7a548310654",
      USDC: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607"
  
    },
  };